.checkout-form {
  display: flex;
  flex-direction: column;
}

.pay-button {
  background-color: #424242;
  color: #CCC;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: max-content;
  margin-top: 12px;
}

.pay-button:hover {
  background-color: #303030;
}

.pay-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.card-element-container {
  background-color: #1a1a1a;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.input-field {
  display: flex;
  flex-direction: column;
}

.input-row {
  display: flex;
  justify-content: space-between;
}

.input-row .input-field {
  flex: 1;
}

.input-row .input-field:not(:last-child) {
  margin-right: 8px;
}

label {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 4px;
}

input {
  padding: 8px;
  background-color: #222;
  border: 2px solid #444;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
}

.InputContainer .InputElement {
  color: #ffffff;
}

.stripe-info {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.stripe-input {
  padding: 8px;
  background-color: #222;
  border: 2px solid #444;
  border-radius: 4px;
}

